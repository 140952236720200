import common from '@mixins/common';
import prompt401 from '@assets/images/401/img_001.png';
export default {
  name: 'Page401',
  mixins: [common],
  components: {},
  data() {
    return {
      headerTitle: '',
      serviceTel: '',
      origin: '',
      prompt401
    };
  },
  props: {},
  computed: {},
  methods: {
    goBack() {
      // 页面回退
      console.log('返回首页');
    },
    goHome() {
      this.$router.replace({
        path: '/'
      });
    },
    async initData() {}
  },
  filters: {},
  watch: {},
  created() {},
  mounted() {
    this.initData();
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  },
  beforeRouteUpdate(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  }
};